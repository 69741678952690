<template>
  <div class="app">
    <loading v-if="isLoading" :process="loadingProcess" />
    <template v-else>
      <!-- <div :class="['home-page', { 'home-page-bg': !isStarted }]" v-if="!isStarted">
        <div class="home-page-content">
          <div class="pos-btn" @click="handleStart"></div>
          <img class="home-page-image cg1" :src="cgImgResource[0]" v-if="cgIndex === 1" />
          <img class="home-page-image cg2" :src="cgImg" v-if="cgIndex === 2" />
        </div>
      </div> -->
      <div>
        <img class="image" :src="imgHome" />
        <expand-image
          v-for="(item, index) of source.images"
          :image="item"
          :panelImages="`e${index + 1}`"
          :key="`source_${index}`"
        />
        <img src="http://ali-img.kaiyanapp.com/70c3b3a918e5f493768fec4b55ad6976.gif" alt="" class="share-icon" @click="handleShare" />
      </div>
    </template>
  </div>
</template>

<script>
import source from "./source";
import expandImage from "./components/expandImage.vue";
import loading from "./components/loading.vue";
import load from "./load";
import { callShareModal } from "./jsbridge";
export default {
  name: 'App',
  components: {
    expandImage,
    loading,
  },
  data() {
    return {
      source: Object.freeze(source),
      isStarted: false,
      imgHome: "http://ali-img.kaiyanapp.com/18b2bd9e04d7f255cd9f9013253a1da9.gif",
      cgImgResource: ["http://ali-img.kaiyanapp.com/69757baac19ce66c5c905de38de24659.gif", "http://ali-img.kaiyanapp.com/b1ef7f3387325f18e355b91b08f61330.gif", "http://ali-img.kaiyanapp.com/9cc8787fb54a163f3a31529e056c118b.gif"],
      cgImg: "",
      isLoading: true,
      loadingProcess: 0,
      cgIndex: 0,
      canStart: false
    }
  },
  methods: {
    handleStart() {
      if (!this.canStart) return
      this.isStarted = true;
    },
    handleShare() {
      const share_info = [
        {
          "type": "share",
          "title": "微信",
          "icon": "https://static.thefair.net.cn/config/icon/share/v1/share_wechat@3x.png",
          "data": {
            "platform": "wechat_friends",
            "share_type": "IMAGE",
            "link": "https://coach.kaiyanapp.com",
            "title": "COACH，波普，红唇，谁促成了这场派对",
            "text": "",
            "image_url": "http://ali-img.kaiyanapp.com/a0cfc97c6a0c21d962b5e349d20fa84f.jpeg?imageMogr2/quality/60/format/jpg",
          },
        },
        {
          "type": "share",
          "title": "朋友圈",
          "icon": "https://static.thefair.net.cn/config/icon/share/v1/share_wechat_moments@3x.png",
          "data": {
            "platform": "wechat_moments",
            "share_type": "h5",
            "link": "https://coach.kaiyanapp.com",
            "title": "COACH，波普，红唇，谁促成了这场派对",
            "text": "",
            "image_url": "http://ali-img.kaiyanapp.com/a0cfc97c6a0c21d962b5e349d20fa84f.jpeg?imageMogr2/quality/60/format/jpg",
          },
        },
        {
          "type": "share",
          "title": "QQ",
          "icon": "https://static.thefair.net.cn/config/icon/share/v1/share_qq@3x.png",
          "data": {
            "platform": "qq",
            "share_type": "IMAGE",
            "link": "https://coach.kaiyanapp.com",
            "title": "COACH，波普，红唇，谁促成了这场派对",
            "text": "",
            "image_url": "http://ali-img.kaiyanapp.com/a0cfc97c6a0c21d962b5e349d20fa84f.jpeg?imageMogr2/quality/60/format/jpg",
            "callback": ""
          },
        },
        {
          "type": "share",
          "title": "QQ空间",
          "icon": "https://static.thefair.net.cn/config/icon/share/v1/share_qq_zone@3x.png",
          "data": {
            "platform": "qzone",
            "share_type": "IMAGE",
            "link": "https://coach.kaiyanapp.com",
            "title": "COACH，波普，红唇，谁促成了这场派对",
            "text": "",
            "image_url": "http://ali-img.kaiyanapp.com/a0cfc97c6a0c21d962b5e349d20fa84f.jpeg?imageMogr2/quality/60/format/jpg",
            "callback": ""
          },
        },
        {
          "type": "share",
          "title": "微博",
          "icon": "https://static.thefair.net.cn/config/icon/share/v1/share_weibo@3x.png",
          "data": {
            "platform": "weibo",
            "share_type": "IMAGE",
            "link": "https://coach.kaiyanapp.com",
            "title": "COACH，波普，红唇，谁促成了这场派对",
            "text": "COACH，波普，红唇，谁促成了这场派对 https://coach.kaiyanapp.com",
            "image_url": "http://ali-img.kaiyanapp.com/a0cfc97c6a0c21d962b5e349d20fa84f.jpeg?imageMogr2/quality/60/format/jpg",
            "callback": ""
          },
        },
        {
          "type": "copy_link",
          "title": "复制链接",
          "icon": "https://static.thefair.net.cn/config/icon/share/v1/share_link@3x.png",
          "data": {
            "link": "https://coach.kaiyanapp.com",
          },
        },
      ]

      callShareModal(share_info);
    }
  },
  // watch: {
  //   isLoading: {
  //     handler(val) {
  //       if (!val) {
  //         this.cgIndex = 1

  //         setTimeout(() => {
  //           this.cgImg = this.cgImgResource[1]
  //           this.cgIndex = 2
  //         }, 4620)

  //         setTimeout(() => {
  //           this.cgImg = this.cgImgResource[2]
  //           this.canStart = true
  //         }, 8000)
  //       }
  //     }
  //   }
  // },
  beforeMount() {
    // const loadingEl = document.querySelector("#loading")

    let imageSource = source.images.slice()

    Object.keys(source.expands).forEach(key => {
      imageSource = imageSource.concat(source.expands[key])
    })

    imageSource.push(this.imgHome, "http://ali-img.kaiyanapp.com/d3d3d6808cf858c45b8fe01db283ab92.jpeg?imageMogr2/quality/60/format/jpg")

    const loadContext = new load(imageSource, () => {
      const preloadCount = Math.floor((loadContext.loadedCount / loadContext.total) * 100)

      // loadingEl.innerHTML = preloadCount + '%'
      this.loadingProcess = preloadCount

      if (loadContext.loadedCount >= loadContext.total) {
        // loadingEl.remove()
        this.isLoading = false

        // load other source
        // let imageSourceOther = []

        // Object.keys(source.expands).forEach(key => {
        //   imageSourceOther = imageSourceOther.concat(source.expands[key])
        // })

        // const loadContextOther = new load(imageSourceOther)

        // loadContextOther.on()
      }
    })

    loadContext.on()
  }
}
</script>

<style>
html,
body,
.app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 0;
  background: #d4d9d1;
}
.image {
  width: 100%;
}

.home-page {
  width: 100%;
  height: 100%;
  position: relative;
}

.home-page.home-page-bg {
  background: url("http://ali-img.kaiyanapp.com/d3d3d6808cf858c45b8fe01db283ab92.jpeg?imageMogr2/quality/60/format/jpg");
}

.home-page-content {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
}

.home-page-image {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.home-page .pos-btn {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 25%;
  width: 50%;
  height: 10%;
  z-index: 20;
}

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 20;
  background: #d4d9d1;

  font-size: 40px;
  align-items: center;
  justify-content: center;
}

.share-icon {
  display: block;
  margin: 0 auto;
}
</style>
