function randomString(length) {
  var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

function postMessage(path, query){
  let queryStr = ''
  if (query) {
    queryStr = '?'
    for (let key in query) {
      queryStr += `${key}=${query[key]}&`
    }
    queryStr = queryStr.slice(0, queryStr.length - 1)
  }
  const random = randomString(6)
  const jsBridgeStr = `eyepetizerjsb://${path}:${random}${queryStr}`
  let uaString = navigator.userAgent.toLowerCase();
  let isIOS = !!uaString.match(/(iphone|ipod|ipad)/);
  let nameSpace = window
  if (isIOS) {
    nameSpace = window.webkit && window.webkit.messageHandlers
  }
  nameSpace && nameSpace._EYEPETIZER && nameSpace._EYEPETIZER.postMessage(jsBridgeStr)
}

// 调起分享弹窗
export function callShareModal(share_info) {
  share_info = encodeURIComponent(JSON.stringify(share_info));
  postMessage('callShareModal', {share_info})
}